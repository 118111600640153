<template>
  <div>
    <ion-content fullscreen />
    <ion-footer class="ion-padding">
      <ion-text color="secondary">
        <h3
          class="ion-no-margin"
          color="secondary"
        >
          <b>Wachtwoord vergeten</b>
        </h3>
        <p class="mt-5">
          Vul je e-mailadres in. Er wordt een reset link gestuurd.
        </p>
      </ion-text>
      <form
        ref="form"
        method="post"
        @submit="forgotPassword"
      >
        <ion-input
          type="email"
          placeholder="E-mailadres"
          name="loginName"
          required
        />
        <ion-button
          strong
          color="primary"
          expand="block"
          type="submit"
        >
          Versturen
        </ion-button>
      </form>
      <a
        class="link mt-20"
        @click="login"
      >
        <b>Terug naar inloggen</b>
      </a>
    </ion-footer>
  </div>
</template>

<script>
import { modalController, alertController } from '@ionic/core';
import Login from '@/components/Login.vue';
import LoginService from '@/services/Login';

export default {
  props: {
    $router: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * Send forgot password request to Craft.
     *
     * @param {Event} e
     */
    async forgotPassword(e) {
      e.preventDefault();

      // Prepare form for login
      const body = new FormData(e.target);
      const response = await LoginService.forgotPassword(body);

      // Handle response
      if (response.success) {
        const alert = await alertController.create({
          header: 'Wachtwoord vergeten',
          message: 'Check uw e-mail voor instructies om uw wachtwoord opnieuw in te stellen.',
          buttons: [{
            text: 'OK',
            handler: this.login,
          }],
          delegate: this.$ionic.alertController.delegate,
        });
        await alert.present();
      } else {
        const alert = await alertController.create({
          header: 'Wachtwoord opnieuw instellen mislukt',
          message: 'Probeer het opnieuw a.u.b.',
          buttons: [{
            text: 'OK',
          }],
          delegate: this.$ionic.alertController.delegate,
        });
        await alert.present();
      }
    },
    /**
     * Login method.
     */
    async login() {
      await modalController.dismiss();

      const modal = await modalController.create({
        component: Login,
        componentProps: {
          propsData: {
            $router: this.$router,
          },
        },
        showBackdrop: false,
        backdropDismiss: false,
        delegate: this.$ionic.modalController.delegate,
      });

      await modal.present();
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: none;
}

ion-input {
  --padding-top: 20px;
  --padding-end: 20px;
  --padding-bottom: 20px;
  --padding-start: 20px;
}

ion-footer {
  border-radius: 30px 30px 0 0;
  background: #fff;
}
</style>
