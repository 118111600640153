import Base from './Base';

/**
 * Planning service.
 */
export class Planning extends Base {
  /**
   * Get planning.
   *
   * @return {<Promise<Object>}
   */
  getPlanning() {
    return this.get('actions/plantingpower/planning/get-planning');
  }
}

export default new Planning();
