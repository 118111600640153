<template>
  <ion-card
    :color="color"
    :button="active"
    :disabled="status < 2"
    class="ion-no-margin"
    @click="openRoute"
  >
    <ion-icon
      v-if="icon"
      :name="icon"
      class="icon-absolute"
    />
    <ion-row class="ion-align-items-center">
      <ion-col
        class="ion-no-padding"
        size="9"
      >
        <ion-card-header>
          <ion-card-title>{{ value.name }}</ion-card-title>
          <p class="ion-no-margin mt-5">
            {{ value.street }} {{ value.houseno }}<br>
            {{ value.postcode }} {{ value.city }}
          </p>
        </ion-card-header>
      </ion-col>
      <ion-col
        class="ion-no-padding"
        size="3"
      >
        <ion-card-content class="ion-text-center">
          <ion-icon name="leaf" />
          <br>
          <ion-text>{{ value.plants.join(' + ') }}</ion-text>
        </ion-card-content>
      </ion-col>
    </ion-row>
  </ion-card>
</template>

<script>
import ClientService from '@/services/Client';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    status() {
      return ClientService.getStatus(this.value.route, this.value.id);
    },
    color() {
      if (this.status > 1) {
        return this.value.next ? 'secondary' : 'light';
      }

      return null;
    },
    active() {
      return this.status === 2;
    },
    icon() {
      switch (this.status) {
        case 0:
          return 'checkmark-circle';
        case 1:
          return 'close-circle';
        default:
          return false;
      }
    },
  },
  methods: {
    /**
     * Open route.
     */
    openRoute() {
      if (this.active) {
        this.$router.push({
          name: 'client',
          params: {
            route: this.value.route,
            client: this.value.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
ion-card {
  display: flex;
  align-items: center;
  overflow: visible;

  .icon-absolute {
    position: absolute;
    top: 50%;
    left: -16px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-color: white;

    &[name="checkmark-circle"] {
      color: #4ac422;
    }

    &[name="close-circle"] {
      color: #d92e2e;
    }
  }

  ion-card-content {
    ion-icon {
      width: 25px;
      height: 25px;
      margin: 0;
      color: #1fb3ab;
    }

    ion-text {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
