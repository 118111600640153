import Vue from 'vue';

import '@/plugins/ionic';
import '@/plugins/moment';
import '@/plugins/img-orientation-changer';
import router from '@/plugins/router';

import '@/assets/css/variables.css';
import '@/assets/css/style.css';

import App from './App.vue';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
