<template>
  <div>
    <button
      v-if="photo"
      type="button"
      class="photo"
      @click="deletePhoto"
    >
      <ion-icon
        name="close-circle"
        class="icon-delete"
      />
      <ion-thumbnail>
        <img
          v-img-orientation-changer
          :src="photo"
          alt=""
        >
      </ion-thumbnail>
    </button>
    <label
      v-else
      :for="`photo-${_uid}`"
      class="photo"
    >
      <ion-icon
        name="camera"
        class="icon-camera"
      />
    </label>
    <input
      :id="`photo-${_uid}`"
      :name="name"
      type="file"
      accept="image/*"
      class="hidden"
      @change="setPhoto"
    >
  </div>
</template>

<script>
import { alertController } from '@ionic/core';

export default {
  props: ['name', 'src'], // eslint-disable-line vue/require-prop-types
  data() {
    return {
      photo: this.$props.src,
    };
  },
  methods: {
    /**
     * Set photo preview.
     *
     * @param {Event} e
     */
    async setPhoto({ target: input }) {
      if (input.files && input.files[0]) {
        this.$emit('change', input.files[0]);
        this.photo = await this.readFile(input.files[0]);
      }
    },
    /**
     * Open alert to delete photo.
     */
    async deletePhoto() {
      const ctrl = alertController;
      const alert = await ctrl.create({
        header: 'Foto verwijderen',
        message: 'Weet u zeker dat u deze foto wilt verwijderen?',
        buttons: [
          {
            text: 'Verwijderen',
            handler: () => {
              this.$emit('change', null);
              this.photo = null;
            },
          },
          {
            text: 'Annuleren',
          },
        ],
        delegate: this.$ionic.alertController.delegate,
      });
      alert.present();
    },
    /**
     * Read file as promise.
     *
     * @param {File}
     *
     * @return {Promise<String>}
     */
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = ({ target: { result } }) => {
          resolve(result);
        };
        reader.onerror = () => {
          reader.abort();
          reject();
        };
        reader.readAsDataURL(file);
      });
    },
  },
};
</script>

<style scoped>
.photo {
  display: block;
  position: relative;
  width: 100%;
  margin: 5px 0 0;
  padding: 100% 0 0;
  border-radius: 5px;
  background-color: #f2f4f4;
  background-position: center;
  background-size: cover;

  input {
    display: none;
  }

  .icon-delete {
    position: absolute;
    z-index: 50;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: white;
    color: #1fb3ab;
  }

  .icon-camera {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin: -15px 0 0 -15px;
    color: #1fb3ab;
  }

  ion-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
  }
}
</style>
