<template>
  <div>
    <ion-content
      fullscreen
      class="ion-padding"
    >
      <form ref="form">
        <ion-grid no-padding>
          <ion-row>
            <ion-col>
              <ion-label><b>Tijd in *</b></ion-label>
              <ion-datetime-vue
                v-model="timeIn"
                name="timeIn"
                display-format="HH:mm"
              />
            </ion-col>
            <ion-col>
              <ion-label><b>Tijd uit *</b></ion-label>
              <ion-datetime-vue
                v-model="timeOut"
                name="timeOut"
                display-format="HH:mm"
              />
            </ion-col>
          </ion-row>
        </ion-grid>

        <p class="ion-no-margin mt-20">
          <b>Taken *</b>
        </p>
        <ion-list lines="none">
          <ion-item
            v-for="(task, index) in tasks"
            :key="`task-${index}`"
          >
            <ion-checkbox-vue
              slot="start"
              v-model="checked[index]"
              name="tasks[]"
              :value="task"
              color="secondary"
            />
            <ion-label>{{ task }}</ion-label>
          </ion-item>
        </ion-list>

        <p class="ion-no-margin mt-20">
          <b>Bijzonderheden</b>
        </p>
        <ion-textarea-vue
          :rows="2"
          name="remarks"
        />

        <p class="ion-no-margin mt-20">
          <b>Foto's</b>
        </p>
        <ion-grid no-padding>
          <ion-row>
            <ion-col
              v-for="n in 4"
              :key="`photo-${n}`"
            >
              <photo name="photos[]" />
            </ion-col>
          </ion-row>
        </ion-grid>

        <p class="ion-no-margin mt-20">
          <b>Volgende keer te doen *</b>
        </p>
        <ion-textarea-vue
          v-model="todo"
          :rows="2"
          name="todo"
        />

        <p class="ion-no-margin mt-30">
          <b>Meldingen</b>
        </p>
        <ion-list lines="none">
          <ion-item>
            <ion-checkbox-vue
              slot="start"
              v-model="notification"
              name="notification"
              :value="1"
              color="secondary"
            />
            <ion-label>Verkoopkans</ion-label>
          </ion-item>
        </ion-list>

        <template v-if="notification">
          <p class="ion-no-margin mt-20">
            <b>Verkoopkans *</b>
          </p>
          <ion-textarea-vue
            v-model="note"
            :rows="2"
            name="note"
          />
        </template>
      </form>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          slot="start"
          color="light"
          fill="solid"
          strong
          @click="modalController.dismiss()"
        >
          Annuleren
        </ion-button>
        <ion-button
          v-if="valid"
          slot="end"
          :disabled="locked"
          color="primary"
          fill="solid"
          strong
          @click="submit"
        >
          Opslaan
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>
</template>

<script>
import { modalController, alertController } from '@ionic/core';
import Photo from '@/components/Photo.vue';
import ClientService from '@/services/Client';

export default {
  components: {
    Photo,
  },
  props: {
    $router: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    route: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      timeIn: null,
      timeOut: null,
      todo: '',
      notification: null,
      note: '',
      checked: [],
      tasks: [
        'Substraat aanvullen',
        'Planten stofvrij maken',
        'Voeding geven',
        'Snoeien',
        'Ziektebestrijding',
        'Planten vervangen',
        'Dood blad verwijderd',
        'Planten gedraaid',
        'Moswand onderhouden',
        'Plantenbakken schoonmaken',
        'Onderhoud plantenwand',
      ],
      locked: false,
    };
  },
  computed: {
    valid() {
      const hasTask = this.checked.filter((task) => task).length;
      return this.timeIn && this.timeOut && hasTask && this.todo !== '' && (!this.notification || this.note !== '');
    },
  },
  watch: {
    timeIn: {
      handler(value) {
        if (value) {
          window.localStorage.setItem('timeIn', value);
        } else {
          const timeIn = window.localStorage.getItem('timeIn');
          if (timeIn) {
            this.timeIn = timeIn;
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.modalController = modalController;
  },
  methods: {
    /**
     * Submit report.
     */
    async submit() {
      if (this.locked) {
        return;
      }

      this.locked = true;
      const data = new FormData(this.$refs.form);
      const result = await ClientService.submitReport(this.client.id, data);
      this.locked = false;

      if (result) {
        ClientService.setStatus(this.route, this.client.id, 0);
        window.localStorage.removeItem('timeIn');

        await modalController.dismiss();
        this.$router.push({ name: 'route', params: { id: this.route } }); // eslint-disable-line vue/no-mutating-props
      } else {
        const alert = await alertController.create({
          header: 'Opslaan mislukt',
          message: 'Er ging iets mis bij het opslaan. Probeer het opnieuw.',
          buttons: ['OK'],
          delegate: this.$ionic.alertController.delegate,
        });
        await alert.present();
      }
    },
  },
};
</script>

<style scoped>
.ion-page {
  ion-content {
    --ion-background-color: white;
  }
}

ion-list {
  margin-bottom: 0;
}

ion-item {
  --padding-start: 0;
  --background-activated: none;
}

ion-grid {
  margin: 0 -5px;
}

ion-footer {
  ion-toolbar {
    --padding-top: 10px;
    --padding-start: 15px;
    --padding-bottom: 10px;
    --padding-end: 15px;
  }

  ion-button {
    --padding-top: 15px;
    --padding-bottom: 15px;
    --opacity: 1;

    font-size: 13px;
  }
}
</style>
