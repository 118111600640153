/* eslint-disable class-methods-use-this */
import Base from './Base';

/**
 * Client service.
 */
export class Client extends Base {
  /**
   * Get client.
   *
   * @param {Number} id
   *
   * @return {Promise<Object>}
   */
  getClient(id) {
    return this.get('actions/plantingpower/client/get-client', { id });
  }

  /**
   * Get client status.
   *
   * @param {String} route
   * @param {String} client
   *
   * @return {Number}
   */
  getStatus(route, client) {
    return parseInt(window.localStorage.getItem(`route-${route}-client-${client}`) || 2, 10);
  }

  /**
   * Set client status.
   *
   * @param {String} route
   * @param {String} client
   * @param {Number} status
   */
  setStatus(route, client, status) {
    window.localStorage.setItem(`route-${route}-client-${client}`, status);
  }

  /**
   * Submit report.
   *
   * @param {Number} id
   * @param {FormData} data
   *
   * @return {Promise<Object>}
   */
  submitReport(id, data) {
    data.set('action', 'plantingpower/client/submit-report');
    data.set('id', id);

    return this.post(data);
  }
}

export default new Client();
