import Vue from 'vue';
import { IonicVueRouter } from '@ionic/vue';
import EventBus from '@/eventBus';
import routes from '@/routes';
import LoginService from '@/services/Login';

Vue.use(IonicVueRouter);

// Initialize router
const router = new IonicVueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Add route guard for pages that need authentication
router.beforeEach(async (to, from, next) => {
  // Don't animate transition going to or from login
  EventBus.$emit('animated', to.name !== 'login' && from.name !== 'login');

  // Verify session
  const session = await LoginService.sessionInfo();

  // Go home if already logged in
  if (to.name === 'login' && !session.isGuest) {
    next('/');
    return;
  }

  // Go to login if not logged in
  if (to.name !== 'login' && session.isGuest) {
    next('/login');
    return;
  }

  next();
});

export default router;
