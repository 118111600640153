/* eslint-disable class-methods-use-this */
import EventBus from '@/eventBus';
import Base from './Base';

/**
 * Route service.
 */
export class Route extends Base {
  route;

  /**
   * Get route.
   *
   * @param {Number} int
   *
   * @return {Promise<Object>}
   */
  async getRoute(id) {
    if (!this.route || this.route.id !== id) {
      this.route = await this.get('actions/plantingpower/route/get-route', {
        id: parseInt(id, 10),
      });
    }

    return this.route;
  }

  /**
   * Get current position.
   *
   * @return {Promise}
   */
  getCurrentPosition() {
    EventBus.$emit('loading', true);

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        EventBus.$emit('loading', false);
        resolve(position);
      }, (error) => {
        EventBus.$emit('loading', false);
        reject(error);
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
      });
    });
  }

  /**
   * Get optimized  clients order.
   *
   * @param {Object} clients
   * @param {Object} position
   *
   * @return {Promise<Array>}
   */
  async getOptimizedClientsOrder(clients, position) {
    const current = `${position.coords.latitude},${position.coords.longitude}`;

    // Set locations to optimize
    const locations = [current];
    clients.forEach((client) => {
      locations.push(`${client.street} ${client.houseno}, ${client.city}`);
    });

    // Do the API request
    const params = new URLSearchParams({
      json: JSON.stringify({ locations }),
      outFormat: 'json',
      key: process.env.VUE_APP_MAPQUEST_KEY,
    });
    const request = await fetch(`https://www.mapquestapi.com/directions/v2/optimizedRoute?${params}`);
    const response = await request.json();

    // Get optimized sequence from API
    const sequence = response.route.locationSequence;

    // Set clients with optimized sequence
    this.route.clients = sequence.slice(1).map((index) => clients[index - 1]);
    return this.route.clients;
  }

  /**
   * Get route status.
   *
   * @param {String} route
   *
   * @return {Number}
   */
  getStatus(route) {
    return parseInt(window.localStorage.getItem(`route-${route}`) || 0, 10);
  }

  /**
   * Set route status.
   *
   * @param {String} route
   * @param {Number} status
   */
  setStatus(route, status) {
    window.localStorage.setItem(`route-${route}`, status);
  }
}

export default new Route();
