<template>
  <ion-page v-if="route">
    <toolbar />
    <ion-content
      fullscreen
      scroll-y="false"
    >
      <div class="content ion-padding">
        <h3 class="ion-no-margin">
          <b>{{ route.day | moment('dddd D MMMM') }}</b>
        </h3>
        <div class="flex my-10">
          <ion-button
            class="button"
            @click="getRoute"
          >
            <ion-icon
              slot="icon-only"
              size="small"
              name="sync"
            />
          </ion-button>
          <p class="ion-no-margin">
            {{ route.title }}
          </p>
        </div>
        <p v-if="route.remarks">
          <em v-html="route.remarks" />
        </p>
        <client
          v-for="(client, index) in clients"
          :key="`${client.id}-${index}`"
          :value="client"
        />
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <p><b>{{ finished }} van {{ route.clients.length }}</b> behandeld</p>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { alertController } from '@ionic/core';
import Toolbar from '@/components/Toolbar.vue';
import Client from '@/components/Client.vue';
import RouteService from '@/services/Route';
import ClientService from '@/services/Client';

export default {
  components: {
    Toolbar,
    Client,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      route: null,
    };
  },
  computed: {
    clients() {
      return this.route.clients.map((client, index, clients) => ({
        ...client,
        next: clients.findIndex((c) => ClientService.getStatus(this.id, c.id) === 2) === index,
        route: this.id,
      }));
    },
    finished() {
      let count = 0;
      this.route.clients.forEach((client) => {
        if (window.localStorage.getItem(`route-${this.id}-client-${client.id}`)) {
          count += 1;
        }
      });
      return count;
    },
  },
  async mounted() {
    this.route = await RouteService.getRoute(this.id);

    // Save number of clients for this route
    RouteService.setStatus(this.id, this.route.clients.length);
  },
  methods: {
    /**
     * Get route.
     */
    async getRoute() {
      try {
        const { clients } = this.route;
        const position = await RouteService.getCurrentPosition();
        this.route.clients = await RouteService.getOptimizedClientsOrder(clients, position);
      } catch (e) {
        const alert = await alertController.create({
          header: 'Locatie ophalen',
          message: 'Het ophalen van je huidige locatie is niet gelukt.',
          buttons: ['Ok'],
          delegate: this.$ionic.alertController.delegate,
        });
        await alert.present();
      }
    },
  },
};
</script>

<style scoped>
.content {
  padding-bottom: 160px;
}

.flex {
  display: flex;
  align-items: center;

  .button {
    --background: #f2f4f4;
    --padding-top: 10px;
    --padding-start: 10px;
    --padding-bottom: 10px;
    --padding-end: 10px;
    --color: #1fb3ab;
    --background-hover: #092322;

    margin-right: 10px;
  }
}

ion-footer {
  ion-toolbar {
    --padding-top: 0;
    --padding-start: 15px;
    --padding-bottom: 0;
    --padding-end: 15px;

    color: #092322;
  }
}
</style>
