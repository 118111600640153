/* eslint-disable class-methods-use-this */
import EventBus from '@/eventBus';

/**
 * Base service.
 */
export default class Base {
  /**
   * @var {Object}
   */
  options = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };

  /**
   * Do a GET request
   *
   * @param {String} query
   * @param {Mixed} params
   *
   * @return {Promise<Object|Boolean}>}
   */
  async get(query, params = null) {
    EventBus.$emit('loading', true);

    let url = `/${query}`;
    if (params) {
      url += `&${new URLSearchParams(params)}`;
    }
    const request = await fetch(url, {
      ...this.options,
      method: 'GET',
    });

    const response = await this.handleResponse(request);

    EventBus.$emit('loading', false);

    return response;
  }

  /**
   * Do a POST request
   *
   * @param {Object} body
   *
   * @return {Promise<Object|Boolean}>}
   */
  async post(body) {
    EventBus.$emit('loading', true);

    const request = await fetch('/index.php', {
      ...this.options,
      method: 'POST',
      body,
    });

    const response = await this.handleResponse(request);

    EventBus.$emit('loading', false);

    return response;
  }

  /**
   * Handle response.
   *
   * @param {Request}
   *
   * @return {Response|Boolean}
   */
  async handleResponse(request) {
    let response;

    switch (request.status) {
      case 200:
        response = await request.json();
        break;
      case 403:
        window.location.reload();
        break;
      default:
        response = false;
        break;
    }

    return response;
  }
}
