import Planning from '@/views/Planning.vue';
import Login from '@/views/Login.vue';
import Profile from '@/views/Profile.vue';
import Route from '@/views/Route.vue';
import Client from '@/views/Client.vue';

export default [
  {
    path: '/',
    name: 'planning',
    component: Planning,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },
  {
    path: '/route/:route',
    name: 'route',
    component: Route,
    props: (route) => ({ id: parseFloat(route.params.route, 10) }),
  },
  {
    path: '/route/:route/client/:client',
    name: 'client',
    component: Client,
    props: (route) => ({ id: route.params.client, route: parseFloat(route.params.route, 10) }),
  },
  {
    path: '*',
    name: 'notfound',
    redirect: '/',
  },
];
