import Base from './Base';

/**
 * Profile service.
 */
export class Profile extends Base {
  profile;

  /**
   * Get profile.
   *
   * @return {Promise<Object>}
   */
  async getProfile() {
    if (!this.profile) {
      this.profile = await this.get('actions/plantingpower/profile/get-profile');
    }

    return this.profile;
  }
}

export default new Profile();
