<template>
  <div id="app">
    <ion-app>
      <ion-vue-router :animated="animated" />
    </ion-app>
  </div>
</template>

<script>
import { loadingController } from '@ionic/core';
import EventBus from '@/eventBus';

export default {
  data() {
    return {
      animated: true,
      loader: null,
      timer: null,
    };
  },
  async mounted() {
    EventBus.$on('loading', async (loading) => {
      if (loading) {
        await this.showLoader();
      } else {
        await this.createLoader();
      }
    });

    EventBus.$on('animated', (animated) => {
      this.animated = animated;
    });

    await this.$nextTick();
    await this.createLoader();
  },
  methods: {
    /**
     * Show loader.
     */
    showLoader() {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          if (this.loader) {
            this.loader.present();
          }
        }, 500);
      }
    },
    /**
     * Hide loader.
     */
    async hideLoader() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      await this.loader.dismiss();

      if (this.loader.parentNode) {
        this.loader.parentNode.removeChild(this.loader);
      }
    },
    /**
     * Dismiss and create new loader.
     */
    async createLoader() {
      if (this.loader) {
        this.hideLoader();
      }

      this.loader = await loadingController.create({
        message: 'Even geduld a.u.b.',
        delegate: this.$ionic.loadingController.delegate,
      });
    },
  },
};
</script>
