import Vue from 'vue';
import Ionic from '@ionic/vue';
import { addIcons } from 'ionicons5';
import {
  camera,
  checkmarkCircle,
  closeCircle,
  leaf,
  sync,
  navigate,
  arrowBackSharp,
} from 'ionicons5/icons';
import '@ionic/core/css/ionic.bundle.css';

Vue.use(Ionic, {
  mode: 'md',
});

addIcons({
  camera,
  'checkmark-circle': checkmarkCircle,
  'close-circle': closeCircle,
  leaf,
  sync,
  navigate,
  'arrow-back-sharp': arrowBackSharp,
});
