<template>
  <ion-page>
    <toolbar />
    <ion-content
      fullscreen
      scroll-y="false"
    >
      <div
        v-if="week"
        class="content ion-padding"
      >
        <h3 class="ion-no-margin">
          <b>Mijn planning</b>
        </h3>
        <p class="ion-no-margin mt-5">
          Week {{ week.weekNumber }}
        </p>

        <week-day
          v-for="item in week.planning"
          :key="item.day"
          :value="item"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import WeekDay from '@/components/WeekDay.vue';
import PlanningService from '@/services/Planning';

export default {
  components: {
    Toolbar,
    WeekDay,
  },
  data() {
    return {
      week: null,
    };
  },
  async mounted() {
    this.week = await PlanningService.getPlanning();
  },
};
</script>
