<template>
  <div>
    <ion-content fullscreen />
    <ion-footer class="ion-padding">
      <ion-grid no-padding>
        <ion-row class="ion-align-items-end ion-margin-bottom">
          <ion-col>
            <ion-text color="secondary">
              <h1
                class="ion-no-margin"
                color="secondary"
              >
                <b>Inloggen</b>
              </h1>
            </ion-text>
          </ion-col>
          <ion-col class="ion-text-right">
            <img
              alt="Planting Power"
              src="../assets/img/icon.svg"
              width="90"
            >
          </ion-col>
        </ion-row>
      </ion-grid>
      <form
        ref="form"
        method="post"
        @submit="login"
      >
        <input
          type="hidden"
          name="rememberMe"
          value="1"
        >
        <ion-input
          tabindex="1"
          type="email"
          placeholder="E-mailadres"
          name="loginName"
          required
        />
        <div class="password">
          <button
            tabindex="3"
            type="button"
            class="password__button"
            @click="forgotPassword"
          >
            ?
          </button>
          <ion-input
            tabindex="2"
            placeholder="Wachtwoord"
            name="password"
            type="password"
            required
          />
        </div>
        <ion-button
          strong
          color="primary"
          expand="block"
          type="submit"
        >
          Login
        </ion-button>
      </form>
    </ion-footer>
  </div>
</template>

<script>
import { modalController, alertController } from '@ionic/core';
import ForgotPassword from '@/components/ForgotPassword.vue';
import LoginService from '@/services/Login';

export default {
  props: {
    $router: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * Login to Craft.
     *
     * @param {Event} e
     */
    async login(e) {
      e.preventDefault();

      // Do login request
      const body = new FormData(e.target);
      const response = await LoginService.login(body);

      // Handle response
      if (response.success) {
        await modalController.dismiss();
        this.$router.replace({ name: 'planning' });
      } else {
        const alert = await alertController.create({
          header: 'Inloggen mislukt',
          message: 'Probeer het opnieuw a.u.b.',
          buttons: [{
            text: 'OK',
          }],
          delegate: this.$ionic.alertController.delegate,
        });
        await alert.present();
      }
    },
    /**
     * Trigger forgot password modal.
     */
    async forgotPassword() {
      await modalController.dismiss();

      const modal = await modalController.create({
        component: ForgotPassword,
        componentProps: {
          propsData: {
            $router: this.$router,
          },
        },
        showBackdrop: false,
        backdropDismiss: false,
        delegate: this.$ionic.modalController.delegate,
      });

      await modal.present();
    },
  },
};
</script>

<style scoped>
ion-content {
  --background: none;
}

ion-input {
  --padding-top: 20px;
  --padding-end: 20px;
  --padding-bottom: 20px;
  --padding-start: 20px;
}

ion-footer {
  border-radius: 30px 30px 0 0;
  background: #fff;
}

.password {
  position: relative;

  &__button {
    position: absolute;
    z-index: 100;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    border: 2px solid #d9dfe0;
    border-radius: 20px;
    background: #f2f4f4;
    color: #092322;
    font-size: 14px;
    font-weight: bold;
    line-height: 35px;
    text-align: center;
  }
}
</style>
