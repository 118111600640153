<template>
  <ion-header>
    <ion-toolbar color="secondary">
      <ion-thumbnail
        v-if="$route.name === 'planning'"
        slot="start"
        class="ml-10"
      >
        <img
          alt="Planting Power"
          src="../assets/img/icon-diap.svg"
        >
      </ion-thumbnail>
      <ion-buttons
        v-else
        slot="start"
      >
        <ion-back-button default-href="/" />
      </ion-buttons>
      <ion-buttons
        v-if="profile"
        slot="end"
      >
        <ion-button
          class="profile"
          @click="$route.name !== 'profile' && $router.push({ name: 'profile' })"
        >
          <ion-label
            slot="start"
            class="ion-text-right"
          >
            <h3><b>{{ profile.name }}</b></h3>
            <p>{{ profile.licensePlate }}</p>
          </ion-label>
          <ion-avatar
            slot="end"
            class="ml-10"
          >
            <img
              :alt="profile.name"
              :src="profile.avatar"
            >
          </ion-avatar>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import ProfileService from '@/services/Profile';

export default {
  data() {
    return {
      profile: null,
    };
  },
  async mounted() {
    this.profile = await ProfileService.getProfile();
  },
};
</script>
