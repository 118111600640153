<template>
  <ion-card
    :color="color"
    :button="active"
    :disabled="!active"
    class="ion-no-margin"
    @click="openRoute"
  >
    <ion-icon
      v-if="icon"
      v-bind="icon"
      class="icon-absolute"
    />
    <ion-card-header>
      <ion-card-title>
        {{ value.day | moment('dddd D MMMM') }}
      </ion-card-title>
      <p class="ion-no-margin mt-5">
        <span v-if="value.route">{{ value.route.title }}</span>
        <em v-else>Geen route</em>
      </p>
      <p
        v-if="value.route && value.route.remarks"
        class="ion-no-margin mt-5"
      >
        <em v-html="value.route.remarks" />
      </p>
    </ion-card-header>
  </ion-card>
</template>

<script>
import RouteService from '@/services/Route';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    count() {
      let count = 0;

      if (!this.value.route) {
        return count;
      }

      Object.keys(window.localStorage).forEach((key) => {
        const regex = new RegExp(`route-${this.value.route.id}-(.*?)`);
        if (key.match(regex)) {
          count += 1;
        }
      });

      return count;
    },
    status() {
      if (this.count && RouteService.getStatus(this.value.route.id) === this.count) {
        return 0;
      }

      return this.$moment(this.value.day).isBefore(this.$moment(), 'day') ? 1 : 2;
    },
    color() {
      if (this.status > 1) {
        return this.$moment(this.value.day).isSame(this.$moment(), 'day') ? 'secondary' : 'light';
      }

      return null;
    },
    active() {
      return this.status >= 2 && this.value.route;
    },
    icon() {
      switch (this.status) {
        case 0:
          return {
            name: 'checkmark-circle',
          };
        case 1:
          return {
            name: this.count ? 'checkmark-circle' : 'close-circle',
            style: { color: this.count ? '#ed8936' : null },
          };
        default:
          return null;
      }
    },
  },
  methods: {
    /**
     * Open route.
     */
    openRoute() {
      if (this.active) {
        this.$router.push({
          name: 'route',
          params: {
            route: this.value.route.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
ion-card {
  overflow: visible;

  .icon-absolute {
    position: absolute;
    top: 50%;
    left: -16px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background-color: white;

    &[name="checkmark-circle"] {
      color: #4ac422;
    }

    &[name="close-circle"] {
      color: #d92e2e;
    }
  }
}
</style>
