import Base from './Base';

/**
 * Login service.
 */
export class Login extends Base {
  /**
   * Log in.
   *
   * @param {FormData} data
   *
   * @return {Promise<Object>}
   */
  login(data) {
    data.set('action', 'users/login');

    return this.post(data);
  }

  /**
   * Send password reset mail.
   *
   * @param {FormData} data
   *
   * @return {Promise<Object>}
   */
  forgotPassword(data) {
    data.set('action', 'users/send-password-reset-email');

    return this.post(data);
  }

  /**
   * Get session info.
   *
   * @return {Promise<Object>}
   */
  sessionInfo() {
    return this.get('actions/users/session-info&dontExtendSession=1');
  }

  /**
   * Log out.
   *
   * @return {Promise<Object>}
   */
  logout() {
    return this.get('actions/users/logout');
  }
}

export default new Login();
