<template>
  <ion-page>
    <toolbar />
    <ion-content scroll-y="false">
      <div
        v-if="profile"
        class="content ion-padding"
      >
        <h3 class="ion-no-margin mt-5">
          <b>{{ profile.fullName }}</b>
        </h3>
        <p class="ion-no-margin mt-5">
          {{ profile.licensePlate }}
        </p>

        <ion-button
          strong
          class="mt-20"
          color="primary"
          expand="block"
          @click="logout"
        >
          Uitloggen
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import LoginService from '@/services/Login';
import ProfileService from '@/services/Profile';

export default {
  components: {
    Toolbar,
  },
  data() {
    return {
      profile: null,
    };
  },
  async mounted() {
    this.profile = await ProfileService.getProfile();
  },
  methods: {
    /**
     * Log out.
     */
    async logout() {
      const response = await LoginService.logout();

      if (response.success) {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style scoped>
ion-grid {
  margin: 0 -5px;
}

.avatar {
  width: 100%;
  height: 100%;

  --border-radius: 5px;
}
</style>
