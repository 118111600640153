<template>
  <ion-page>
    <toolbar />
    <ion-content
      v-if="client"
      fullscreen
    >
      <div class="content ion-padding">
        <h3 class="ion-no-margin">
          <b>{{ client.name }}</b>
        </h3>
        <div class="flex my-10">
          <ion-button
            :href="directions"
            class="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ion-icon
              slot="icon-only"
              size="small"
              name="navigate"
            />
          </ion-button>
          <p class="ion-no-margin">
            {{ client.street }} {{ client.houseno }}<br>
            {{ client.postcode }} {{ client.city }}
          </p>
        </div>
        <hr class="my-10">
        <ion-grid no-padding>
          <ion-row class="py-10">
            <ion-col><b>Aantal planten</b></ion-col>
            <ion-col class="ion-text-right">
              {{ client.plants.join(' + ') }}
            </ion-col>
          </ion-row>
          <ion-row class="py-10">
            <ion-col><b>Klant sinds</b></ion-col>
            <ion-col class="ion-text-right">
              {{ client.clientSince | moment('DD-MM-YYYY') }}
            </ion-col>
          </ion-row>
          <ion-row class="py-10">
            <ion-col>
              <b>Contactperson(en)</b>
              <p
                v-for="contact in client.contacts"
                :key="`contact-${contact.id}`"
                class="ion-no-margin mt-5"
              >
                {{ contact.name }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row class="py-10">
            <ion-col><b>Telefoonnummer</b></ion-col>
            <ion-col class="ion-text-right">
              <a :href="`tel:${client.phone}`">{{ client.phone }}</a>
            </ion-col>
          </ion-row>
        </ion-grid>
        <hr class="my-10">
        <p><b>Opmerkingen</b></p>
        <p>{{ client.note }}</p>
        <hr class="my-10">
        <p><b>Deze keer te doen</b></p>
        <p>{{ client.todo }}</p>
      </div>
    </ion-content>
    <ion-footer v-if="active">
      <ion-toolbar>
        <ion-button
          slot="start"
          color="light"
          fill="solid"
          strong
          @click="close"
        >
          Niet afgerond
        </ion-button>
        <ion-button
          slot="end"
          color="primary"
          fill="solid"
          strong
          @click="report"
        >
          Maak rapport
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { modalController } from '@ionic/core';
import Toolbar from '@/components/Toolbar.vue';
import Report from '@/components/Report.vue';
import RouteService from '@/services/Route';
import ClientService from '@/services/Client';

export default {
  components: {
    Toolbar,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    route: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      client: null,
      active: false,
    };
  },
  computed: {
    directions() {
      if (this.client) {
        const address = `${this.client.street} ${this.client.houseno}, ${this.client.city}`;
        return `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;
      }

      return null;
    },
  },
  async mounted() {
    this.client = await ClientService.getClient(this.id);

    const route = await RouteService.getRoute(this.route);
    this.active = !route.readOnly && this.$moment(route.day).isSame(this.$moment(), 'day');
  },
  methods: {
    /**
     * Close.
     */
    close() {
      ClientService.setStatus(this.route, this.id, 1);
      this.$router.go(-1);
    },
    /**
     * Report.
     */
    async report() {
      const modal = await modalController.create({
        component: Report,
        componentProps: {
          propsData: {
            $router: this.$router,
            client: this.client,
            route: this.route,
          },
        },
        delegate: this.$ionic.modalController.delegate,
      });
      await modal.present();
    },
  },
};
</script>

<style scoped>
.content {
  padding-bottom: 176px;
}

hr {
  height: 2px;
  margin: 0;
  padding: 0;
  background: #f2f4f4;
}

ion-item {
  --background: white;
  --padding-start: 0;
  --inner-padding-end: 0;

  font-size: 16px;
  line-height: 1.5;
}

.flex {
  display: flex;
  align-items: center;

  .button {
    --background: #f2f4f4;
    --padding-top: 10px;
    --padding-start: 10px;
    --padding-bottom: 10px;
    --padding-end: 10px;
    --color: #1fb3ab;
    --background-hover: #092322;

    margin-right: 10px;
  }
}

ion-footer {
  color: #092322;

  ion-toolbar {
    --padding-top: 10px;
    --padding-start: 15px;
    --padding-bottom: 10px;
    --padding-end: 15px;
  }

  ion-button {
    --padding-top: 15px;
    --padding-bottom: 15px;
    --opacity: 1;

    font-size: 13px;
  }
}
</style>
