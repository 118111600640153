<template>
  <ion-page>
    <ion-content
      fullscreen="true"
      scroll-y="false"
    />
  </ion-page>
</template>

<script>
import { modalController } from '@ionic/core';
import Login from '@/components/Login.vue';

export default {
  async mounted() {
    const modal = await modalController.create({
      component: Login,
      componentProps: {
        propsData: {
          $router: this.$router,
        },
      },
      showBackdrop: false,
      backdropDismiss: false,
      delegate: this.$ionic.modalController.delegate,
    });
    await modal.present();
  },
};
</script>

<style scoped>
ion-content {
  --background: none;

  background-image: url("../assets/img/bg.jpg");
  background-position: center;
  background-size: cover;
}
</style>
